import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessmentResult, Course } from '@shared/models';
import { AssessmentService } from '../../shared/services/assessment.service';
import { CoursesAPIService } from '@shared/services/coursesApi.service';

enum Error {
  'default' = 'default',
  'rateLimit' = 'rateLimit'
}

@Component({
  selector: 'learner-report',
  templateUrl: './learner-report.component.html',
  styleUrls: ['./learner-report.component.scss']
})
export class LearnerReportComponent implements OnInit {
  public course?: Course;
  public assessmentResult?: AssessmentResult;
  public error?: Error;

  constructor(
    private _route: ActivatedRoute,
    private _courseService: CoursesAPIService,
    private _assessmentService: AssessmentService,
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe(async params => {
      const courseId = params.get('courseId');
      const sessionId = params.get('sessionId');
      this.loadData(courseId, sessionId);
    });
  }

  private loadData(courseId: string, sessionId: string) {
    this._courseService.getCourseInfo(courseId).subscribe(course => {
      this.course = course;
    });
    
    this._assessmentService.getAssessmentBySessionId(sessionId).subscribe(existingAssessment => {
      this.assessmentResult = existingAssessment;
    }, (e) => {
      if (e.status !== 404) {
        this.error = Error.default;
        return;
      }

      this._assessmentService.generate(sessionId, courseId).subscribe(assessmentResult => {
        this.assessmentResult = assessmentResult;
      }, (e) => {
        this.error = e.status === 429 ? Error.rateLimit : Error.default;
      });
    });
  }

  restart() {
    history.back();
  }
}
